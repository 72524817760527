<template>
  <article>
    <form action="">
      <h1 class="tit">성과 관리</h1>      
      <DataForm ref="formData" @sendFormData="handleFormData" :compType="'modi'" :isCanceled="isCanceled"/>
      <!-- <DataForm />       -->
      <div class="pop-btn flex__c__m mgt20">
        <button v-if="isMine" @click.prevent.stop="handleDelete()" type="button" class="btn__rev">삭제</button>
        <button v-if="isMine" @click="handleEdit()" type="button" class="btn__base"> 수정</button>
      </div>
    </form>
  </article>
</template>

<script setup>
import { ref, defineProps, shallowRef, markRaw, nextTick, computed } from 'vue';
import { useStore } from 'vuex';
import DataForm from '@/screens/cms/performance/popup/DataForm.vue';
import { deletePerfDetailAPI, uploadFileAPI, deleteFileAPI } from '@/api'

const store = useStore();
const props = defineProps({
  popup: { type: Object, required: true },
  closePopup: { type: Function, required: true },
});
const formData = shallowRef(markRaw(DataForm));
const isMine = computed(() => store.getters.getIsMine);
const isCanceled = ref(false);

const handleClose = () => {
  props.closePopup(props.popup.id);
}

const handleEdit = () => {
  formData.value.sendFormData();
}

const handleDelete = () => {
  return store.dispatch('openPopup', {
    id: 'Alert',
    data: {
      txt1: '삭제하시겠습니까?',
      txt2: '(삭제 후 복원이 불가합니다.)',
      nextPopup: 'Next',
      nextData: {txt1: '삭제되었습니다.'},
      onConfirm : async () => {
        deletePerfData();
      },
      cancel: true,
      // 취소 눌렀을 때 팝업 꺼줘야하면 아래 onCancle 콜백함수 주석해제
      /*
      onCancel: () => {
        handleClose();
      }
      */
    },
    gubun: null
  });
}

async function handleFormData(formData) {
  try {
    await nextTick()
    return store.dispatch('openPopup', {
      id: 'Alert',
      data: {
        txt1: '수정하겠습니까?',
        nextPopup: 'Next',
        nextData: { txt1: '수정되었습니다' },
        onConfirm: async () => {
            await editPerfData(formData);
        },
        cancel: true,
        onCancel: () => {
          isCanceled.value = !isCanceled.value;
        }
      },
      gubun: null
    });
  } catch (error) {
    console.error(error);
    return store.dispatch('openPopup', {
      id: 'Alert',
      data: { txt1 : '수정할 수 없습니다.'}
    })
  }
}

// 수정
async function editPerfData(formData){
  const response = await store.dispatch('updatePopupData', {
      uriPath: store.getters.getVariables['uriPath'], 
      uriId: store.getters.getVariables['uriId'], 
      data: formData
    })

  if (response.status == '200'){
    if((formData['newFiles'] && formData['newFiles'].length > 0) ||
      (formData['removeFiles'] && formData['removeFiles'].length > 0)) {
      store.dispatch('openPopup', {
          id: 'Loading', 
          data: {
            txt1: '수정중입니다',
            txt2: '첨부파일 용량에 따라 시간이 소요될 수 있습니다.'
          }
        })

      try {
        await manageFile(formData);
        props.closePopup('Loading')
      } catch {
        return store.dispatch('openPopup', 
          {
            id: 'Alert',
            data: {
              txt1: '첨부파일 저장 중 에러가 발생했습니다'
            }
          })
      }
    }
  }
  handleClose();
  store.commit('setIsUpdated', true);
}

// 삭제
async function deletePerfData() {
  const popupData = store.getters.getPopupData
  try {
    const result = await deletePerfDetailAPI(
      store.getters.getVariables['uriPath'],
      popupData[store.getters.getVariables['uriPath'].concat('Id')]
    )
    if (result.status === 200){
      store.commit('setIsUpdated', true);
      handleClose();
    }
  } catch (error) {
    console.error('삭제할 수 없습니다', error);
    return store.dispatch('openPopup', {
      id:'Alert',
      data: {txt1:'삭제할 수 없습니다.'}
    });
  } 
}

async function manageFile (formData) {
  if (formData['newFiles'].length > 0) {
    const fileData = new FormData();
    formData['newFiles'].forEach(fileWrapper => {
      fileData.append('files', fileWrapper.file);
    })
    fileData.append('seqc', store.getters.getVariables['uriId']);
    fileData.append('referenceTable', store.getters.getVariables['uriPath']);
    await uploadFileAPI(fileData);
  }

  if (formData['removeFiles'].length > 0) {
    formData['removeFiles'].forEach(file => {
      deleteFileAPI(file.fileId);
    })
  }
}

</script>