<template>
  <header class="flex__e__m rel">
    <div class="la abs">
      <p class="flex__c__m">매뉴얼</p>
      <ul class="tc">
        <li v-for="(item, index) in manuals" :key="index">
          <a :href="item.link" download>{{ item.label }}</a>
        </li>
      </ul>
    </div>
    <nav class="ra flex__f">
      <p class="user flex__m">{{userInfo.userNm}}님</p>
      <ul class="top-menu flex tc">
        <li v-for="(item, index) in topMenu" :key="index">
          <router-link :to="item.link" class="flex__v__m__c" @click="item.event ? item.event() : null">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path :d="item.iconPath" />
            </svg>
            <span class="block">{{ item.label }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script setup>

import { ref } from 'vue';
import { useCookies } from 'vue3-cookies';
import { useStore } from 'vuex';

const { cookies } = useCookies();
const store = useStore(); 

const userInfo = ref('');
userInfo.value = cookies.get("userInfo");

const logout = () => {
  cookies.remove("userInfo");
  store.commit('setPropUniv', '');
  alert('로그아웃 되었습니다.');
}

const manuals = ref([
  { label: '학습자', link: '/document/pdf/UserManual_stdt.pdf' },
  { label: '교수자', link: '/document/pdf/UserManual_prf.pdf' },
  { label: '교직원', link: '/document/pdf/UserManual_staff.pdf' },
]);
const topMenu = ref([
  {
    label: '마이페이지',
    link: '/lms/mypage',
    iconPath: 'M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z',
  },
  {
    label: '로그아웃',
    link: '/',
    iconPath: 'M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z',
    event: logout,
  },
])
</script>

<style lang="scss" scoped>
.cms {
  header {
    background-color: $wh;
    height: 5rem;
    box-shadow: 0 0 1rem .5rem rgba(0, 0, 0, .05);
    z-index: 100;
    .la {
      $position: 3.6rem;
      top: calc(50% - $position/2 );
      left: 2rem;
      width: 10rem;
      height: $position;
      font-size: 1.3rem;
      background: $primary-tint;
      border-radius: 2rem;
      cursor: default;
      overflow: hidden;
      @include trans();
      p {
        height: $position;
        gap: .8rem;
        color: $primary-light;        
        font-weight: 700; 
        z-index: 2;
        &::before {
          content: "";
          display: block;
          width: 1.6rem;
          height: 2.1rem;
          background: url($imgUrl+'icons/manual.png') no-repeat;
          background-size: contain;
        }
      }
      ul {
        top: $position;
        left: 0;
        right: 0;
        padding: 0 1rem;
        background: $primary-tint;
        li {
          font-size: 1.3rem;
          color: $primary-light;
          a {
            display: block;
            padding: .7rem;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      &:hover {
        height: 13.4rem;
        box-shadow: 0 0 1rem .5rem rgba(15, 38, 122, .1);
      }
    }
    nav {
      height: 100%;
      .user {
        padding: 0 2rem;
        color: $text;
        font-weight: 600;
      }
      .top-menu {
        font-size: 1.2rem;        
        li {
          color: $text;
          border-left: .1rem solid #eee;
          a {
            height: 100%;
            padding: 0 2rem;
            svg {
              width: 1.6em;
              height: 1.6rem;
              path {
                fill: $text-muted;
                @include trans();
              }
            }
            span {
              margin-top:.4rem;
            }
            &:hover {
              font-weight: 500;
              svg {
                path {
                  fill: $text;
                }
              }
            }
          }          
        }
      }
    }
  }
}
</style> 