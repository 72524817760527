<template>
  <article>
    <form action="">
      <h1 class="tit">성과 등록</h1>
      <DataForm ref="formData" @sendFormData="handleFormData" :compType="'add'"/>
      <div class="pop-btn flex__c__m mgt20">
        <button @click="props.closePopup(props.popup.id)" type="button" class="btn__rev">취소</button>
        <button @click="handleAdd()" type="button" class="btn__base"> 등록</button>
      </div>
    </form>
  </article>
</template>

<script setup>
import { defineProps, nextTick, ref } from 'vue';
import { useStore } from 'vuex';
import DataForm from '@/screens/cms/performance/popup/DataForm.vue';
import { uploadFileAPI, getCountOfAleadyAddedAPI } from '@/api'

const store = useStore();
const formData = ref(null)
const props = defineProps({
  popup: { type: Object, required: true },
  closePopup: { type: Function, required: true },
});

const handleAdd = () => {
  formData.value.sendFormData();
}

async function handleFormData(formData) {
  // 등록 로직
  try {
    await nextTick();
    // perfId가 2, 4인 것은 연도와 교과목명이 동일하면 등록못하게 해야함
    if (['2', '4'].includes(formData['perfId'])){
      const result = await getCountOfAleadyAddedAPI(store.getters.getVariables['uriPath'], formData)
      if (result.data > 0){
        return store.dispatch('openPopup', {
          id: 'Alert',
          data: {
            txt1: '이미 등록된 과목입니다'
          }
        });
      } else {
        return store.dispatch('openPopup', {
          id: 'Alert',
          data: {
            txt1: '등록하겠습니까?',
            nextPopup: 'Next',
            nextData: {txt1: '등록되었습니다'},
            onConfirm: async () => {
              savePerfData(formData);
            },
            cancel: true
          }
        });
      }
    } else {
      return store.dispatch('openPopup', {
          id: 'Alert',
          data: {
            txt1: '등록하겠습니까?',
            nextPopup: 'Next',
            nextData: {txt1: '등록되었습니다'},
            onConfirm: async () => {
              savePerfData(formData);
            },
            cancel: true
          }
        });
    }
  } catch (error) {
    console.error(error);
    return store.dispatch('openPopup', {
      id: 'Alert',
      data: {
        txt1: '등록할 수 없습니다.'
      }
    });
  }
}

async function savePerfData (formData) {
  try {
    const response = await store.dispatch('savePopupData', {
     uriPath: store.getters.getVariables['uriPath'],
     data: formData
    })
    if(response.status === 200){
      if (formData['newFiles'] !== null && formData['newFiles'].length > 0) {
        store.dispatch('openPopup', {
          id: 'Loading', 
          data: {
            txt1: '저장중입니다',
            txt2: '첨부파일 용량에 따라 시간이 소요될 수 있습니다.'
          }
        })
        const result = await manageFile(formData, response.data);
        if(result.status === 200){
          props.closePopup('Loading')
        } else {
          return store.dispatch('openPopup', {
            id: 'Alert',
            data: {
              txt1: '첨부파일 저장 중 에러가 발생했습니다'
            }
          })
        }
      }

      props.closePopup(props.popup.id);
      store.commit('setIsUpdated', true);
    }
  } catch (error) {
    console.error(error);
  }
}

async function manageFile(formData, seqc){
  const fileData = new FormData();
  formData['newFiles'].forEach(fileWrapper => {
    fileData.append('files', fileWrapper.file);
  })
  fileData.append('seqc', seqc);
  fileData.append('referenceTable', store.getters.getVariables['uriPath']);
  return await uploadFileAPI(fileData);
}

</script>