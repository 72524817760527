<template>
  <div class="form-box tl mgt20">
    <!-- 연도 & 학기 -->
    <!-- 학년도와 학기가 둘 다 있을 때 : 둘 다 box flex__d__t에 들어가야함 (perfId로 구분해야겠다,,) -->
    <div class="form-group" v-if="(hasSemester.includes(perfId))">
    <!-- <div class="form-group" v-if="(([2, 3, 4, 5, 6, 7, 11, 12]).includes(perfId))"> -->
      <div class="box flex__d__t">
        <div class="la">
          <label for="data1-1"> {{ tableHeaders.includes('운영연도') ? '운영연도' : '개발연도' }} </label>
          <select id="data1-1" class="w100" v-model="formData[tableHeaders.includes('운영연도') ? '운영연도' : '개발연도']"
            :disabled="manageUniv !== userUniv || manageUniv === 'entire'">
            <option v-for="year in years" :key="year" :value="year">{{ year }}년 </option>
          </select>
        </div>  
        <div class="ra">
          <label for="data1-2"> {{ tableHeaders.includes('운영학기') ? '운영학기' : tableHeaders.includes('학기') ? '학기' : '' }} </label>
          <select id="data1-2" class="w100" v-model="formData[tableHeaders.includes('운영학기') ? '운영학기' : '학기']"
          :disabled="manageUniv !== userUniv || manageUniv === 'entire'">
            <option value="1학기">1학기</option>
            <option value="하계">하계</option>
            <option value="2학기">2학기</option>
            <option value="동계">동계</option>
          </select>
        </div>
      </div>
    </div>
    <!-- 연도만 있는 경우 : 연도 select 하나만 랜더링 -->
    <div class="form-group" v-else-if="perfId !== '19'">
      <div class="la">
        <label for="data1-1"> {{ tableHeaders.includes('운영연도') ? '운영연도' : 
                                (tableHeaders.includes('개발연도') ? '개발연도' : 
                                  (tableHeaders.includes('학년도') ? '학년도' : '활용연도'))}} </label>
        <select id="data1-1" class="w100" v-model="formData[tableHeaders.includes('운영연도') ? '운영연도' : 
                                                  (tableHeaders.includes('개발연도') ? '개발연도' : 
                                                    (tableHeaders.includes('학년도') ? '학년도' : '활용연도'))]"
                                                    :disabled="manageUniv !== userUniv || manageUniv === 'entire'">
          <option v-for="year in years" :key="year" :value="year">{{ year }}년</option>
        </select>
      </div>  
    </div>

    <!-- 연도 & 학기를 제외한 컬럼들 -->
    <template v-for="(header, index) in tableHeaders" :key="header">
      <div class="form-group rel" v-if="header === '첨부파일'">
        <label :for="'data'+(index+1)">{{ header }}</label>
        <div :id="'data'+(index+1)" @dragover.prevent @drop.prevent="onDrop" class="file-drop">
        <p v-if="!files.length" class="flex__c__m">          
          <span class="block">여기로 파일을 드래그하세요</span>
        </p>
        <div v-if="files.length">
          <ul class="flex__v">
            <li v-for="(file, index) in files" :key="index" class="flex__d__m">
              <span>{{ file.originalFileName }}</span>
              <button type="button" @click="remove(index)" v-if="isMine && propUniv !== '전체참여대학'">
                <span class="hidden">삭제</span>
              </button>
            </li>
          </ul>          
        </div>
        <button v-if="files.length>0 && (isMine) &&propUniv !== '전체참여대학'" type="button" @click="clear" class="del-btn abs" title="전체삭제"></button>
      </div>
    </div>
    
    <div class="form-group" v-if="!yearFileColumns.includes(header) && !typeDate.includes(header) && !isSub.includes(header)
                                  && (!typeSelect.includes(header) || (typeSelect.includes(header) && perfId === '17'))">
        <label :for="'data'+(index+1)">{{ header }}</label>
        <input :id="'data'+(index+1)" :type="perfId === '17' ? text : settingType(header)" class="w100" v-model="formData[header]" 
        :disabled="isDisabled.includes(header) || manageUniv !== userUniv || manageUniv === 'entire'">
    </div>
    
    <!-- 이공계, 비이공계 number -->
    <div v-else-if="isSub.includes(header)">
      <div  class="form-group" v-for="(sub, index) in subHeaders" :key="index">
          <label v-if="sub!=='합계'" :for="'sub'+(index+1)">{{ sub }} - {{ index }}</label>
          <label v-else :for="'sub'+(index+1)">{{ sub }} - {{ index }}</label>

          <input v-if="sub!=='합계'" :type="typeText.includes(sub) ?'text' : typeNumber.includes(sub) ? number : ''" :id="'sub'+(index+1)" class="w100" v-model="formData[sub]"
          :disabled="manageUniv !== userUniv || manageUniv === 'entire'" />
          <input v-else type="number" :id="'sub'+(index+1)" class="w100" v-model="formData[sub]" :disabled="sub==='합계'"/>
      </div>
    </div>

    <!-- perfId 17은 '구분' 이지만 input type ="text"임 -->
    <div class="form-group" v-else-if="typeSelect.includes(header) && perfId !== '17'">
        <label :for="'data'+(index+1)">{{ header }}</label>
        <select :id="'data'+(index+1)" class="w100" v-model="formData[header]" 
        :disabled="isDisabled.includes(header) || manageUniv !== userUniv || manageUniv === 'entire'">
          <option v-for="opt in optionsMap[header]" :key="opt" :value="opt"> {{ opt }} </option>
        </select>
    </div>

    <div class="form-group" v-else-if="typeDate.includes(header)">
        <label :for="'data'+(index+1)">{{ header }}</label>     
        <DatePicker
          :id="'data'+(index+1)"
          v-model="selectedDate"
          locale="ko" 
          format="yyyy-MM-dd" 
          :enable-time-picker="false" 
          cancelText="취소" 
          selectText="확인" 
          class="DatePicker w100"
					:disabled="manageUniv !== userUniv || manageUniv === 'entire'"
          @update:model-value="handleDate(header)"
        />
      </div>

    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, shallowReactive, defineProps, defineEmits, defineExpose, watch } from 'vue';
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';
import { setTableHeaders, setSubHeader, getItemValue, getColName } from '@/api/setPerf';
import DatePicker  from '@vuepic/vue-datepicker';

const emit = defineEmits(['sendFormData']);
const props = defineProps({
  compType: String,
  isCanceled: Boolean,
});
const { cookies } = useCookies();
const store = useStore();

const startYear = 2023;
const endYear = 2026;
const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
const startMonth = 1;
const endMonth = 12;
const months = Array.from({length: endMonth}, (_, i) => (String)((startMonth + i)).concat('월'))
const selectedDate = ref(null);

const userUniv = ref('')
const usersFk = ref(0)
userUniv.value = cookies.get("userInfo").university
usersFk.value = cookies.get("userInfo").userSeq
const manageUniv = ref('')

const files = ref([]);
const popupData = computed(() => store.getters.getPopupData || {});
const perfId = computed(() => store.getters.getPerfGubun || null); // 몇 번 성과지표인가
const pathId = ref(0); // 각 성과지표 데이터 id값 (해당하는 테이블의 seqc) 예) advEduDev의 경우 advEduDevId

const tableHeaders = ref([]);
const subHeaders = ref([]);
const formData = shallowReactive({});

const propUniv = computed(() => store.getters.getPropUniv);
const isMine = computed(() => store.getters.getIsMine);

onMounted(async () => {
  // 최초 페이지 열었을 때 formData 초기화
  pathId.value = store.getters.getVariables['uriId'];
  manageUniv.value = store.getters.getManageUniv['data'];
  initializeFormData();
  await fetchDetailData();
  await settingData();
})

// formData, files 초기화
const initializeFormData = () => {
  tableHeaders.value = [];
  subHeaders.value = [];
  optionsMap.value = [];
  if (files.value.length > 0) {
    files.value = [];
  }
}

watch(() => props.isCanceled, async (newCancelBool) => {
  // 수정을 취소했을 경우, 기존 데이터로 바꿔줘야댐
  if (newCancelBool || !newCancelBool) {
    await fetchDetailData();
    await settingData(); 
  }
})

const fetchDetailData = async () => {
  tableHeaders.value = setTableHeaders(String(perfId.value))
  if (['5', '6', '13', '14'].includes(String(perfId.value))){
    subHeaders.value = setSubHeader(String(perfId.value))
  }
  if(props.compType === 'modi') { 
    await store.dispatch('fetchPopupData', {uriPath: store.getters.getVariables['uriPath'], uriId: pathId.value,})
    .then(() => {
      if(popupData.value.files != null && popupData.value.files != undefined && popupData.value.files.length > 0){
        files.value = popupData.value.files
      }
    })
  }
}

//  학기가 있는  perfId 
const hasSemester = ['2', '3', '4', '5', '6', '7', '11', '12'];
const isDisabled = ['전임/비전임', '이수자수(총합)', '대학명', '컨소시엄명', '채용형태'];

// subHeader까지 포함한 내용임
const typeText = ['전임/비전임', '성명', '전공(경력)', '역할', '비고'];
const typeNumber = ['운영건수', '건수', '이수자수', '학생 수', '참여교원(명)', '참가자 수', '이공계', '비이공계', '소계', 
                  '강좌주차수', '설문참여대상자(명)', '설문참여자(명)', '만족도평균(점)', '활용실적(건)', '활용실적(명)', '활용인원(명)', 
                  '대상자', '수혜교원(명)', '실적(%, 수)', '수혜율(%)'];
const typeSelect = [ '대학명' , '구분', '개발주체', '참여형태', '활용형태', '강좌수준', '개발시기', '자체평가 진행시기', 
                    '품질검수 진행시기', '운영시기', '자체평가여부', '품질검수여부', '운영여부', '개발여부', '학점연계여부', '이수증발급여부'];
const typeTextArea = ['세부내용', '강좌소개'];
const yearFileColumns = ['학기', '학년도', '활용연도', '운영연도', '개발연도', '운영학기', '첨부파일'];
const typeDate = ['일시', '운영일자', '운영일시', '임용일자']
const isSub = ['이수자수(총합)', '교원정보']

const settingType = (header) => {
  if (typeText.includes(header)){
		return 'text';
	} else if (typeNumber.includes(header)){
		return 'number'
	} else if (typeTextArea.includes(header)){
		return 'textarea';
	} else {
    return 'text';
  }
}

const optionsMap = ref({})
const settingOptions = async (header) => {
  const optionMap = {
    '대학명' : ['국민대학교','서울시립대학교', '울산과학대학교', '전남대학교', '한국항공대학교'], 
    '구분' :  {default :['초급', '중급', '고급'],
                perfIds : {
                  '7' : ['취업률'],
                  '8' : ['창업자 수'],
                  '9' : ['진학률']
                }
      },
    '개발주체' : ['공동', '단일'],
    '참여형태' : ['공동', '단일'],
    '활용형태' : ['대학 내부', '컨소시엄 내부', '컨소시엄 외부'],
    '강좌수준' : ['교양', '전공기초', '전공심화'],
    '개발시기' : months,
    '자체평가 진행시기' : months,
    '품질검수 진행시기' : months,
    '운영시기' : months,
    '자체평가여부' : ['예정', '진행', '완료'],
    '품질검수여부' : ['예정', '진행', '완료'],
    '운영여부' : ['운영예정', '운영중', '운영완료'],
    '개발여부' : ['개발예정', '개발중', '개발완료'],
    '학점연계여부' : ['연계', '미연계'],
    '이수증발급여부' : ['발급', '미발급'],
  }

  if(typeSelect.includes(header)){
    if(optionMap[header]) {
      if (header === '구분' && optionMap[header].perfIds) {
        const perfIdOptions = optionMap[header].perfIds[String(perfId.value)];
        optionsMap.value[header] = perfIdOptions || optionMap[header].default;
      } else {
        optionsMap.value[header] = Array.isArray(optionMap[header] || optionMap[header].default) ? optionMap[header] : optionMap[header.options]
      }
    }
  }
}

const settingData = async () => {
  if (props.compType === 'add'){
    tableHeaders.value.forEach(header => {
      settingOptions(header);
      formData[header] = settingDefaultData(header);
    })
    if (subHeaders.value.length > 0) {
      subHeaders.value.forEach(sub => {
        settingOptions(sub);
        formData[sub] = settingDefaultData(sub);
      })
    }
  } else { // 'edit'인 경우
  // db에 저장된 데이터를 formData 객체에 저장
  tableHeaders.value.forEach(header => {
      settingOptions(header)
      formData[header] = getItemValue(popupData.value, header);
      if (header==='일시' || header === '운영일시' || header === '운영일자' || header === '임용일자') {
        transDateFormat(header);
      }
    })
    
    if(subHeaders.value.length>0){
      subHeaders.value.forEach(sub => {
        settingOptions(sub)
        formData[sub] = getItemValue(popupData.value, sub);
      })
    }
  }
}

// 디폴트값 설정 (신규등록시)
function settingDefaultData(header){
  switch (header) {
    case '학년도' :
    case '활용연도' :
    case '개발연도' :
    case '운영연도' : return new Date().getFullYear();
    case '컨소시엄명' : return '차세대통신';
    case '대학명' : 
      if (['20','21'].includes(perfId.value)){
        return store.getters.getSubTitle['subUniv']
      } else {
//        return userUniv.value;
        return userUniv.value ==='국민대학교' && userUniv.value !== store.getters.getManageUniv['data'] ? store.getters.getManageUniv['data'] : userUniv.value;
      }
    case '학기':
    case '운영학기': return '1학기';
    case '구분':  
      if(perfId.value !== '17' && (perfId.value === '7' || perfId.value === '8' || perfId.value === '9')) { 
        return perfId.value == '7' ? '취업률' : perfId.value == '8' ? '창업자 수' : '진학률';
      } else { return '초급' }
    case '개발주체':
    case '참여형태': return '공동';
    case '전임/비전임' : 
    case '채용형태' : return perfId.value === '13' ? '전임' : '비전임';
    case '활용형태' : return '대학 내부';
    // kmoocSub 강좌구성현황 관련 초기값 세팅해야함
    case '강좌수준' : return '교양';
    case '강좌명' : return store.getters.getSubTitle['subTitle'] || '';
    case '개발시기':
    case '자체평가 진행시기' :
    case '품질검수 진행시기' :
    case '운영시기' : return '1월';
    case '자체평가여부' :
    case '품질검수여부' : return '예정';
    case '개발여부' : return '개발예정';
    case '운영여부' : return '운영예정'
    case '학점연계여부' : return '연계';
    case '이수증발급여부' : return '발급';
    default: return null
  }
}

function transDateFormat(header) { // 저장된 문자열 날짜값을 DatePicker (Date형식으로 변환)
  selectedDate.value = new Date(formData[header]);
}

const handleDate = (header) => { // Date형식을 문자열로 변환 -> db 저장위해 문자열로 변환해야함
  const formattedDate = selectedDate.value.toISOString().split('T')[0];
  formData[header] = formattedDate;
}

const newFiles = ref([]) // 추가해야할 파일
function onDrop(event) {
  const dropped = Array.from(event.dataTransfer.files);
  for (let i = 0; i < dropped.length; i++) {
    const existingFile = files.value.find(file => file.originalFileName == dropped[i].name);
    if(!existingFile) {
      files.value.push({
        "file" : dropped[i],
        "originalFileName": dropped[i].name
      })

      newFiles.value.push({
        "file" : dropped[i],
        "originalFileName": dropped[i].name
      })
    }
  }
}

const removeFiles = ref([]) // 삭제해야할 파일
function remove(index) {
  let file = files.value[index]
  if(file.fileId > 0) {
    files.value.splice(index, 1);
    removeFiles.value.push({
      "fileId" : file.fileId,
      "originalFileName" : file.originalFileName
    })
  } else {
    files.value.splice(index, 1);
  }
}

function clear() {
  for(let i=0; i<files.value.length; i++){
    if (files.value[i]['fileId'] === undefined) {
      files.value.splice(i, 1);
    } else {
      removeFiles.value.push({
        "fileId" : files.value[i]['fileId'],
        "originalFileName" : files.value[i]['originalFileName']
      })
    }
  }
  files.value = [];
}

// 백엔드로 보낼 데이터
const requestData = shallowReactive({})
watch(formData, (newForm) => {
  if (newForm != null) {
    tableHeaders.value.forEach(header => {
      if(header !== '컨소시엄명') {
        const colname = getColName(header)
        requestData[colname] = newForm[header]
      }
    })

    if (subHeaders.value.length > 0) {
      subHeaders.value.forEach(sub => {
        const colname = getColName(sub)
        if (sub === '합계') { // 합계 실시간으로 반영하기
          newForm[sub] = parseInt(newForm['이공계']) + parseInt(newForm['비이공계'])
        }
        requestData[colname] = newForm[sub]
      })
    }

    requestData['perfId'] = perfId.value
    
    if (perfId.value === '10') {
    requestData['univ'] = userUniv.value
  }

    requestData['se'] = store.getters.getVariables['uriPath']
    requestData[store.getters.getVariables['uriPath'].concat('Id')] = pathId.value
    requestData['usersFk'] = usersFk.value
  }
}, {immediate: true})

// 부모컴포넌트에 requestData 전달
function sendFormData() {
  requestData.newFiles = newFiles.value;       // 새로운 파일 추가
  requestData.removeFiles = removeFiles.value; // 삭제할 파일 추가
  emit('sendFormData', requestData);
}

defineExpose({ sendFormData });


</script>

<style lang="scss" scoped>
.cms {
  .form-box {
    width: 40rem;
    .form-group {
      padding: .7rem 0;
      .box {
        .la, .ra {
          width: 47%;
        }
        .mid-txt {
          display: block;
          width: 6%;
          height: 100%;
          line-height: 3.4rem;
        }
      }
      label {
        display: block;
        margin-bottom: .5rem;
        font-size: 1.4rem;
        font-weight: 500;
        color: $bk;
      }
    }
    .file-drop {
      height: 10rem;
      background-color: $btn-bg-hover;
      border: 2px dashed #ddd;
      border-radius: .3rem;
      overflow: auto;
      p {
        gap: 1rem;
        width: 100%;
        height: 100%;
        color: $btn-color;
        font-weight: 400;
        font-size: 1.3rem;
        span {
          padding-top: 2.8rem;
          background: url($imgUrl+'file-import.svg') no-repeat center top;
          background-size: 2rem auto;
        }
      }      
      ul {
        padding: 1rem;
        gap: .4rem;        
        li {
          width: 100%;
          padding: .2rem 1rem;
          border-radius: .3rem;
          background: $btn-bg-on;
          &:hover {
            background: $btn-bg-light;
          }
          cursor: pointer;
          span {
            width: calc(100% - 2rem);
            padding-right: 1rem;
            font-size: 1.3rem;
            line-height: 1.5;
            color: $text;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          button {
            width: 2rem;
            height: 2rem;
            opacity: .6;
            background: url($imgUrl+'close.svg') no-repeat center;
            @include trans($time: .1s);
            img {
              width: 100%;              
            }
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .del-btn {
        width: 2.4rem;
        height: 2.4rem;
        padding: .35rem .6rem;
        line-height: 2.4rem;
        top: .4rem;
        right: 0;
        border-radius: .3rem;
        background: url($imgUrl+'trash.svg') no-repeat center;
        background-size: 50% auto;
        border: .1rem solid $primary-tint;
        @include trans();
        &:hover {
          background-color: $primary-tint;
        }
      }    
    }
  }
}
</style>