<template>
  <div 
    v-for="popup in openPopups" 
    :key="popup.id" 
    @click="popup.dimClose ? closeOutside(popup.id, $event) : null"
    class="popup fix flex__c__m rel"
  >
    <div :class="{'smooth': isTransitioning}" class="popup-content flex__c__m tc rel" >
      
      <component
        :is="!popup.component ? PopupMessage : popup.component"
        :popup="popup"
        :openPopup="openPopup"
        :closePopup="closePopup"
      />
      
      <button v-if="popup.id !== 'Loading'"
        @click="popup.data.onCancel ? onCancel(popup) : closePopup(popup.id)" type="button" class="pop-clz abs">
        <img src="@/assets/cms/img/close.svg" alt="">
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import PopupMessage from '@/components/cms/popup/PopupMessage.vue';
import PerfDataAdd from '@/screens/cms/performance/popup/DataAdd.vue';
import PerfDataEdit from '@/screens/cms/performance/popup/DataEdit.vue';
import PerfDataChoice from '@/screens/cms/performance/popup/DataChoice.vue';

const store = useStore();
const isTransitioning = ref(false);

const popups = ref([
  
  /*
    1. data: {  } 내에 nextPopup: 'nextPopupID' 추가 시 
       팝업 내에서 확인 버튼을 클릭할 경우, 기존 팝업 닫히고 해당 ID를 가진 팝업이 새롭게 열림
       해당 컴포넌트마다 openNext() 관련 함수가 있어야 동작함 (기본 메세지타입에는 이미 작성됨)
    2. 배열 내 'dimClose: false' 추가 시
       팝업 밖 바닥 영역을 클릭하면 팝업 닫히지 않음
       기본값은 true
       예시 : { id: 'TestMessage', data: { txt1: '테스트메세지.' , txt2: '서브메세지', nextPopup:'TestNext', nextData: { txt1: '두번째메세지', txt2:'' } }, dimClose: false }, 
    3. 메세지 타입의 Alert처럼 사용할 수 있는 id: 'Alert' 팝업 호출 방법
       openPopup('Alert', { txt1: '메세지', txt2: '서브메세지', nextPopup:'TestPop' })       
  */

  // message Type 
  // { id: 'Alert', dimClose: true },
  { id: 'Alert', dimClose: false },
  { id: 'Next', dimClose: true },
  { id: 'Loading', dimClose: false },

  // normal Type
  { id: 'PerfDataAdd', component: PerfDataAdd },
  { id: 'PerfDataEdit', component: PerfDataEdit },  
  { id: 'PerfDataChoice', component: PerfDataChoice },
  
]);

const openPopups = computed(() => 
  store.getters.getPopups.map(popup => {
    const defaultPopup = popups.value.find(p => p.id === popup.id);
    const dimClose = 'dimClose' in defaultPopup ? defaultPopup.dimClose : false;
    return { ...defaultPopup, dimClose, data: { ...defaultPopup.data, ...popup.data } };
  })
);

function closePopup(popupId) {
  store.commit('setPopupData', {}); // 팝업을 끄면 store에 저장된 popupData를 비워줘야함
  isTransitioning.value = true;
  setTimeout(() => {
    isTransitioning.value = false;
    store.dispatch('closePopup', popupId);
  }, 200);
}

//  취소 버튼을 누른 다음 로직 데이터 비워주는 콜백함수 추가
const onCancel = (popup) => {
  if (popup.data.onCancel) {
    popup.data.onCancel();
  }
  closePopup(popup.id);
}

function closeOutside(popupId, event) {
  if (event.target.classList.contains('popup')) {
    closePopup(popupId);
  }
}
</script>

<style lang="scss" scoped>
.cms {  
  .popup {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 3rem;
    background: rgba(0, 0, 0, .5);
    overflow: auto;
    z-index: 1000;
    $scr-color: #a5a5a5;
    scrollbar-color: $scr-color transparent;
    &::-webkit-scrollbar-thumb {
      background-color: $scr-color;      
    }
    .popup-content {      
      min-height: 20rem;      
      padding: 5rem 3rem 4rem;
      background: #fff;
      border-radius: .8rem;
      box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, .1);
      animation: moveUp .3s ease;
      transition: all 0.6s ease;
      &.smooth {
        transform: translateY(5rem);
        opacity: 0;
      }
      .pop-clz {
        $btn-size: 2.4rem;
        width: $btn-size;
        height: $btn-size;
        padding: .1rem;
        top: 1.3rem;
        right: 1.3rem;
        background: #eee;
        border-radius: 50%;
        opacity: .5;
        @include trans();
        img {
          width: 100%;
          height: 100%;          
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  @keyframes moveUp {
    0% {
      transform: translateY(5rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
</style>