import ViewCms from '@/view/cms/CmsApp.vue';

export default {
  path: '/cms',
  name: 'CMS',
  component: ViewCms,
  meta: {
    roles: [
      "ROLE_ADMIN",
      "ROLE_PROFESSOR",
      "ROLE_STUDENT",
      "ROLE_SUPER_ADMIN",
      "ROLE_SUPER",
    ],
  },
  children: [
    { // INDEX
      path: '',
      name: 'CmsMain',
      component: () => import('@/screens/cms/main/CmsMain.vue'),
    },
    { // 마이크로디그리
      path: 'microdegree',
      name: 'microdegree',
      children: [
        {
          path: 'apply',
          name: 'MiDegApply',
          component: () => import('@/screens/cms/microdegree/MiDegApply.vue'),
          meta: { title: '이수 신청',
            roles: ["ROLE_STUDENT", "ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SUPER"]
           },
        },
        {
          path: 'history',
          name: 'MiDegHistory',
          component: () => import('@/screens/cms/microdegree/MiDegHistory.vue'),
          meta: { title: '이수 이력',
            roles: ["ROLE_STUDENT", "ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SUPER"]
           },
        },
      ],      
    },
    { // 사업비 관리
      path: 'pms',
      name: 'PmsList',      
      component: () => import('@/screens/cms/pms/PmsList.vue'),
      meta: { title: '사업비 관리' },
    },
    {  // 성과 관리
      path: 'performance',
      name: 'PerfList',      
      component: () => import('@/screens/cms/performance/PerfList.vue'),
      meta: { title: '성과 관리',
            roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_SUPER"]
       },
    },
  ],
};