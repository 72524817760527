<template>
  <article>
    <div v-if="props.popup.id === 'Loading'"  class="loading flex__c__m">
      <p v-if="props.popup.data.txt1" class="t1">{{ props.popup.data.txt1 }}</p>
      <p v-if="props.popup.data.txt2" class="t2 mgt10">{{ props.popup.data.txt2 }}</p>
      <div class="ring mgt10"></div>
    </div>
    <div v-else>
      <p v-if="props.popup.data.txt1" class="t1">{{ props.popup.data.txt1 }}</p>
      <p v-if="props.popup.data.txt2" class="t2 mgt10">{{ props.popup.data.txt2 }}</p>
    </div>
    <div class="pop-btn flex__c__m mgt20" v-if="props.popup.id !== 'Loading'">
      <button v-if="props.popup.data.cancel" 
        @click="props.popup.data.onCancel ? onCancel() : props.closePopup(props.popup.id)" type="button" class="btn__wh">취소</button>
      <button @click="props.popup.data.onConfirm ? confirm() : 
              props.popup.data.nextPopup ? openNext() : props.closePopup(props.popup.id)" type="button" class="btn__bk">확인</button>
    </div>
  </article>
</template>

<script setup>
import { defineProps } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const props = defineProps({
  popup: { type: Object, required: true },
  closePopup: { type: Function, required: true },
  openPopup: { type: Function, required: true },
});

// 확인 버튼을 누른 다음에 로직이 실행되어야하므로 추가
const confirm = async () => {
  if(props.popup.data.onConfirm) {
      props.popup.data.onConfirm();
      if (props.popup.data.nextPopup) {
        store.commit('setIsUpdated', true);
        openNext();
      }
  }
}

//  취소 버튼을 누른 다음 로직 데이터 비워주는 콜백함수 추가
const onCancel = () => {
  if (props.popup.data.onCancel) {
    props.popup.data.onCancel();
  }
  props.closePopup(props.popup.id);
}

function openNext() {
  if (props.popup.data.nextPopup) {
    props.closePopup(props.popup.id);
    props.openPopup(props.popup.data.nextPopup, props.popup.data.nextData || {});
  } else {
    props.closePopup(props.popup.id);
  }
}
</script>

<style lang="scss" scoped>
.cms .popup {
  article {
    width: auto;
    min-width: 24rem;
    max-width: 30rem;
    p {
      color: $bk;
      line-height:1.2;
      &.t1 {
        font-size: 1.8rem;
        font-weight:600;
      }
      &.t2 {
        opacity: .8;
      }
    }  
  }
}
</style>